<template>
  <rcc-select
    v-model="selectedValues"
    v-bind="$attrs"
    return-object
    multiple
    :items="items"
    :show-by="showBy"
    :track-by="trackBy"
    class="multiselect"
  >
  <template v-slot:selection="data">
    <v-chip
      class="rcc-autocomplete__chip"
      outlined
      close
      @click:close="handleItemRemove(data.item)"
    >
      <span>{{ data.item[showBy] }}</span>
    </v-chip>
  </template>

  <template v-slot:item="data">
    <v-list-item-content v-text="data.item[showBy]"></v-list-item-content>
  </template>
  </rcc-select>
</template>

<script>
import RccSelect from 'Components/controls/rcc-select'

export default {
  name: 'RccMultiselect',

  components: {
    RccSelect
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    },

    trackBy: {
      type: String,
      default: 'id'
    },

    showBy: {
      type: String,
      default: 'name'
    }
  },

  computed: {
    selectedValues: {
      get () {
        return this.items.filter(option => (this.value || []).includes(option[this.trackBy]))
      },

      set (value) {
        this.$emit('select', value.map(item => item[this.trackBy]))
        this.$emit('input', value.map(item => item[this.trackBy]))
      }
    }
  },

  methods: {
    handleItemRemove(item) {
      const index = this.selectedValues.findIndex(option => option[this.trackBy] === item[this.trackBy])

      if (index >= 0) {
        this.selectedValues = this.selectedValues.filter(value => value !== item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  .v-input__slot {
    background: $text-input-bg !important;
  }

  fieldset {
    border-color: $text-input-border-color;
  }

  &:hover {
    fieldset {
      border-color: $text-input-hovered-border-color;
    }
  }

  .v-select__selections {
    padding: 6px 0 !important;
  }

  .v-label, .v-select__selection, .v-list-item__title {
    font-size: $base-font-size;
  }

  &.v-input--is-disabled {
    fieldset {
      border-color: $text-input-disabled-border-color;
    }

    &:hover {
      fieldset {
        border-color: $text-input-disabled-border-color;
      }
    }
  }

  .v-chip {
    max-width: 300px;
    padding-right: 10px;
    margin: 1px 4px !important;

    .v-chip__content {
      padding-right: 17px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .v-icon {
      position: absolute;
      right: 0;
    }
  }
}
</style>
