<template>
  <div class="return-points-view">
    <p v-if="!returnPoints.length">
      Список пуст
    </p>

    <template v-else>
      <div class="actions">
        <rcc-multiselect
          v-if="networkId === $constants.networkIds.x5"
          ref="regionsSelect"
          label="Выбрать привязанные к региону"
          :items="activeNetworkRegions"
          v-model="selectedRegions"
          hide-details
          trackBy="id"
          showBy="name"
          class="regions-select"
          @input="handleRegionSelect"
        />

        <div class="fast-select-section">
          <rcc-button @click="selectAllDcs">Выбрать все</rcc-button>
          <rcc-button @click="unselectAllDcs" isOutlined >Отменить все</rcc-button>
        </div>
      </div>

      <rcc-checkboxes-group
        :value="selectedReturnPoints"
        choose-by="id"
        show-by="name"
        :options="returnPoints"
        class="checkboxes-grid"
        @input="updateSelectedPointsList"
      />
    </template>
  </div>
</template>

<script>
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'
import RccButton from 'Components/controls/rcc-button'
import RccMultiselect from 'Components/controls/selects/multiselect'

export default {
  name: 'RccReturnPointsView',

  components: {
    RccCheckboxesGroup,
    RccButton,
    RccMultiselect
  },

  props: {
    networkId: {
      type: Number,
      default: null
    },

    selectedReturnPoints: {
      type: Array,
      default: () => []
    },

    regions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      returnPoints: [],
      previouslySelectedRegionsIds: [],
      selectedRegions: []
    }
  },

  computed: {
    allReturnPointsIds() {
      return this.returnPoints.map(({ id }) => id)
    },

    activeNetworkRegions() {
      if (this.networkId !== this.$constants.networkIds.x5) {
        return []
      }

      return this.regions.filter(({ id }) => this.returnPoints.some(({ region_id }) => region_id === id))
    }
  },

  mounted() {
    this.getReturnPoints()
  },

  methods: {
    getReturnPoints() {
      if (!this.networkId) {
        return
      }

      this.$apiMethods.returnPoints.dcList({ network_id: this.networkId })
        .then(points => {
          this.returnPoints = points.filter(({ available }) => available)
        })
    },

    updateSelectedPointsList(points) {
      this.$emit('selected-points-change', points)

      if (this.selectedReturnPoints.length < points.length) {
        return
      }

      const regionsWithSelectedPoints = new Set(
        this.returnPoints
          .filter(({ id }) => points.includes(id))
          .map(({ region_id }) => region_id)
      )

      this.selectedRegions = this.selectedRegions.filter(regionId =>
        regionsWithSelectedPoints.has(regionId)
      )

      this.previouslySelectedRegionsIds = this.selectedRegions
    },

    selectAllDcs() {
      const newPointsList = Array.from(new Set([...this.selectedReturnPoints, ...this.allReturnPointsIds]))
      this.updateSelectedPointsList(newPointsList)
    },

    unselectAllDcs() {
      const newPointsList = this.selectedReturnPoints.filter(point => !this.allReturnPointsIds.includes(point))

      this.updateSelectedPointsList(newPointsList)
    },

    handleRegionSelect(regionIds) {
      if (regionIds.length > this.previouslySelectedRegionsIds.length) {
        const addedRegionIds = regionIds.filter(id => !this.previouslySelectedRegionsIds.includes(id))

        const returnPointIdsToAdd = this.returnPoints
          .filter(item =>  addedRegionIds.includes(item.region_id))
          .map(({ id }) => id)

        const updatedSelectedPoints = Array.from(new Set([...this.selectedReturnPoints, ...returnPointIdsToAdd]))

        this.updateSelectedPointsList(updatedSelectedPoints)
      } else {
        const removedRegionIds = this.previouslySelectedRegionsIds.filter(id => !regionIds.includes(id))

        const returnPointsIdsToRemove = this.returnPoints
          .filter(item =>  removedRegionIds.includes(item.region_id))
          .map(({ id }) => id)

        this.updateSelectedPointsList(
          this.selectedReturnPoints.filter(point => !returnPointsIdsToRemove.includes(point))
        )
      }

      this.previouslySelectedRegionsIds = regionIds
    }
  }
}
</script>

<style lang="scss" scoped>
.return-points-view {
  margin-top: 10px;

  .actions {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    .fast-select-section {
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .checkboxes-grid {
    margin-top: 20px;
    width: 90%;

    & > div {
      width: 33%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;

      & > div {
        width: 50%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 100%;
      }
    }
  }

  .regions-select {
    max-width: 300px;
  }
}
</style>
